exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-active-lifestyle-index-js": () => import("./../../../src/pages/active-lifestyle/index.js" /* webpackChunkName: "component---src-pages-active-lifestyle-index-js" */),
  "component---src-pages-areas-bikini-js": () => import("./../../../src/pages/areas/bikini.js" /* webpackChunkName: "component---src-pages-areas-bikini-js" */),
  "component---src-pages-areas-brazilian-js": () => import("./../../../src/pages/areas/brazilian.js" /* webpackChunkName: "component---src-pages-areas-brazilian-js" */),
  "component---src-pages-areas-darkskin-js": () => import("./../../../src/pages/areas/darkskin.js" /* webpackChunkName: "component---src-pages-areas-darkskin-js" */),
  "component---src-pages-areas-for-men-js": () => import("./../../../src/pages/areas/for-men.js" /* webpackChunkName: "component---src-pages-areas-for-men-js" */),
  "component---src-pages-areas-index-js": () => import("./../../../src/pages/areas/index.js" /* webpackChunkName: "component---src-pages-areas-index-js" */),
  "component---src-pages-areas-legs-js": () => import("./../../../src/pages/areas/legs.js" /* webpackChunkName: "component---src-pages-areas-legs-js" */),
  "component---src-pages-areas-lipnchin-js": () => import("./../../../src/pages/areas/lipnchin.js" /* webpackChunkName: "component---src-pages-areas-lipnchin-js" */),
  "component---src-pages-areas-underarm-js": () => import("./../../../src/pages/areas/underarm.js" /* webpackChunkName: "component---src-pages-areas-underarm-js" */),
  "component---src-pages-bestpractices-photos-js": () => import("./../../../src/pages/bestpractices/photos.js" /* webpackChunkName: "component---src-pages-bestpractices-photos-js" */),
  "component---src-pages-blogs-[author]-author-js": () => import("./../../../src/pages/blogs/author/[author].js" /* webpackChunkName: "component---src-pages-blogs-[author]-author-js" */),
  "component---src-pages-blogs-[blog]-js": () => import("./../../../src/pages/blogs/[blog].js" /* webpackChunkName: "component---src-pages-blogs-[blog]-js" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-bodybuilding-index-js": () => import("./../../../src/pages/bodybuilding/index.js" /* webpackChunkName: "component---src-pages-bodybuilding-index-js" */),
  "component---src-pages-bodymap-index-js": () => import("./../../../src/pages/bodymap/index.js" /* webpackChunkName: "component---src-pages-bodymap-index-js" */),
  "component---src-pages-brochure-index-js": () => import("./../../../src/pages/brochure/index.js" /* webpackChunkName: "component---src-pages-brochure-index-js" */),
  "component---src-pages-bundles-index-js": () => import("./../../../src/pages/bundles/index.js" /* webpackChunkName: "component---src-pages-bundles-index-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-clientsale-index-js": () => import("./../../../src/pages/clientsale/index.js" /* webpackChunkName: "component---src-pages-clientsale-index-js" */),
  "component---src-pages-coupon-index-js": () => import("./../../../src/pages/coupon/index.js" /* webpackChunkName: "component---src-pages-coupon-index-js" */),
  "component---src-pages-covid-index-js": () => import("./../../../src/pages/covid/index.js" /* webpackChunkName: "component---src-pages-covid-index-js" */),
  "component---src-pages-display-index-js": () => import("./../../../src/pages/display/index.js" /* webpackChunkName: "component---src-pages-display-index-js" */),
  "component---src-pages-enter-index-js": () => import("./../../../src/pages/enter/index.js" /* webpackChunkName: "component---src-pages-enter-index-js" */),
  "component---src-pages-folliculitis-index-js": () => import("./../../../src/pages/folliculitis/index.js" /* webpackChunkName: "component---src-pages-folliculitis-index-js" */),
  "component---src-pages-form-processing-index-js": () => import("./../../../src/pages/form/processing/index.js" /* webpackChunkName: "component---src-pages-form-processing-index-js" */),
  "component---src-pages-form-self-success-force-index-js": () => import("./../../../src/pages/form/self-success-force/index.js" /* webpackChunkName: "component---src-pages-form-self-success-force-index-js" */),
  "component---src-pages-form-success-index-js": () => import("./../../../src/pages/form/success/index.js" /* webpackChunkName: "component---src-pages-form-success-index-js" */),
  "component---src-pages-homedevice-index-js": () => import("./../../../src/pages/homedevice/index.js" /* webpackChunkName: "component---src-pages-homedevice-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-about-us-js": () => import("./../../../src/pages/locations/about-us.js" /* webpackChunkName: "component---src-pages-locations-about-us-js" */),
  "component---src-pages-locations-contact-js": () => import("./../../../src/pages/locations/contact.js" /* webpackChunkName: "component---src-pages-locations-contact-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-reviews-js": () => import("./../../../src/pages/locations/reviews.js" /* webpackChunkName: "component---src-pages-locations-reviews-js" */),
  "component---src-pages-lp-anniversarysale-js": () => import("./../../../src/pages/lp/anniversarysale.js" /* webpackChunkName: "component---src-pages-lp-anniversarysale-js" */),
  "component---src-pages-lp-calc-js": () => import("./../../../src/pages/lp/calc.js" /* webpackChunkName: "component---src-pages-lp-calc-js" */),
  "component---src-pages-lp-cost-js": () => import("./../../../src/pages/lp/cost.js" /* webpackChunkName: "component---src-pages-lp-cost-js" */),
  "component---src-pages-lp-index-js": () => import("./../../../src/pages/lp/index.js" /* webpackChunkName: "component---src-pages-lp-index-js" */),
  "component---src-pages-lp-men-js": () => import("./../../../src/pages/lp/men.js" /* webpackChunkName: "component---src-pages-lp-men-js" */),
  "component---src-pages-lp-permanence-js": () => import("./../../../src/pages/lp/permanence.js" /* webpackChunkName: "component---src-pages-lp-permanence-js" */),
  "component---src-pages-lp-savings-js": () => import("./../../../src/pages/lp/savings.js" /* webpackChunkName: "component---src-pages-lp-savings-js" */),
  "component---src-pages-lp-waxing-js": () => import("./../../../src/pages/lp/waxing.js" /* webpackChunkName: "component---src-pages-lp-waxing-js" */),
  "component---src-pages-medicallyrecommended-index-js": () => import("./../../../src/pages/medicallyrecommended/index.js" /* webpackChunkName: "component---src-pages-medicallyrecommended-index-js" */),
  "component---src-pages-military-index-js": () => import("./../../../src/pages/military/index.js" /* webpackChunkName: "component---src-pages-military-index-js" */),
  "component---src-pages-offer-index-js": () => import("./../../../src/pages/offer/index.js" /* webpackChunkName: "component---src-pages-offer-index-js" */),
  "component---src-pages-pcos-index-js": () => import("./../../../src/pages/pcos/index.js" /* webpackChunkName: "component---src-pages-pcos-index-js" */),
  "component---src-pages-press-index-js": () => import("./../../../src/pages/press/index.js" /* webpackChunkName: "component---src-pages-press-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-process-beforeafter-beforeafter-bikini-js": () => import("./../../../src/pages/process/beforeafter/beforeafter_bikini.js" /* webpackChunkName: "component---src-pages-process-beforeafter-beforeafter-bikini-js" */),
  "component---src-pages-process-beforeafter-beforeafter-legs-js": () => import("./../../../src/pages/process/beforeafter/beforeafter_legs.js" /* webpackChunkName: "component---src-pages-process-beforeafter-beforeafter-legs-js" */),
  "component---src-pages-process-beforeafter-beforeafter-lip-js": () => import("./../../../src/pages/process/beforeafter/beforeafter_lip.js" /* webpackChunkName: "component---src-pages-process-beforeafter-beforeafter-lip-js" */),
  "component---src-pages-process-beforeafter-beforeafter-men-js": () => import("./../../../src/pages/process/beforeafter/beforeafter_men.js" /* webpackChunkName: "component---src-pages-process-beforeafter-beforeafter-men-js" */),
  "component---src-pages-process-beforeafter-beforeafter-underarm-js": () => import("./../../../src/pages/process/beforeafter/beforeafter_underarm.js" /* webpackChunkName: "component---src-pages-process-beforeafter-beforeafter-underarm-js" */),
  "component---src-pages-process-beforeafter-index-js": () => import("./../../../src/pages/process/beforeafter/index.js" /* webpackChunkName: "component---src-pages-process-beforeafter-index-js" */),
  "component---src-pages-process-compare-js": () => import("./../../../src/pages/process/compare.js" /* webpackChunkName: "component---src-pages-process-compare-js" */),
  "component---src-pages-process-faqs-js": () => import("./../../../src/pages/process/faqs.js" /* webpackChunkName: "component---src-pages-process-faqs-js" */),
  "component---src-pages-process-index-js": () => import("./../../../src/pages/process/index.js" /* webpackChunkName: "component---src-pages-process-index-js" */),
  "component---src-pages-process-quiz-js": () => import("./../../../src/pages/process/quiz.js" /* webpackChunkName: "component---src-pages-process-quiz-js" */),
  "component---src-pages-share-index-js": () => import("./../../../src/pages/share/index.js" /* webpackChunkName: "component---src-pages-share-index-js" */),
  "component---src-pages-specials-estimate-js": () => import("./../../../src/pages/specials/estimate.js" /* webpackChunkName: "component---src-pages-specials-estimate-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-trans-index-js": () => import("./../../../src/pages/trans/index.js" /* webpackChunkName: "component---src-pages-trans-index-js" */),
  "component---src-pages-welcome-to-milan-index-js": () => import("./../../../src/pages/WelcomeToMilan/index.js" /* webpackChunkName: "component---src-pages-welcome-to-milan-index-js" */),
  "component---src-pages-welcome-to-milan-post-treatment-js": () => import("./../../../src/pages/WelcomeToMilan/post-treatment.js" /* webpackChunkName: "component---src-pages-welcome-to-milan-post-treatment-js" */),
  "component---src-pages-welcome-to-milan-pre-treatment-js": () => import("./../../../src/pages/WelcomeToMilan/pre-treatment.js" /* webpackChunkName: "component---src-pages-welcome-to-milan-pre-treatment-js" */),
  "component---src-pages-welcome-to-milan-skin-care-js": () => import("./../../../src/pages/WelcomeToMilan/SkinCare.js" /* webpackChunkName: "component---src-pages-welcome-to-milan-skin-care-js" */),
  "component---src-templates-builder-page-jsx": () => import("./../../../src/templates/builderPage.jsx" /* webpackChunkName: "component---src-templates-builder-page-jsx" */),
  "component---src-templates-lp-savings-template-js": () => import("./../../../src/templates/LpSavingsTemplate.js" /* webpackChunkName: "component---src-templates-lp-savings-template-js" */),
  "component---src-templates-state-template-js": () => import("./../../../src/templates/StateTemplate.js" /* webpackChunkName: "component---src-templates-state-template-js" */)
}

